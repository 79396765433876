import Bindings from "../bindings";
import type { TSourceType } from "../themes/obs";

type Settings = Record<string, any>;

type Payload = { id: TSourceType; name: string; type: unknown } & {
  settings?: Settings;
};

export class PSource {
  type!: TSourceType;
  name!: string;
  settings: undefined | Settings;

  constructor(payload: Payload) {
    this.patch(payload);
  }

  patch({ id, name, settings }: Payload) {
    this.type = id;
    this.name = name;
    this.settings = settings;
    return this;
  }

  async loadSettings() {
    this.settings = await this.getSettings();
    return this;
  }

  async getSettings() {
    return await Bindings.obs.source_get_settings_json(this.name);
  }

  async setSettings(settings: Settings) {
    this.settings = settings;

    await Bindings.obs.source_set_settings_json(
      this.name,
      JSON.stringify(settings),
    );

    return this;
  }
}

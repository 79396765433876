import * as FingerprintJS from "@fingerprintjs/fingerprintjs";

let p: Promise<FingerprintJS.GetResult> | undefined;

export const useFingerprint = async (): Promise<FingerprintJS.GetResult> => {
  if (!p) {
    p = FingerprintJS.load().then((fp) => fp.get());
  }

  return await p;
};

import type { AsyncDataOptions, NuxtApp } from "#app";

type Options<T> = AsyncDataOptions<
  Maybe<T>,
  Maybe<T>,
  KeysOf<Maybe<T>>,
  Maybe<T>
>;
type Handler<T> = (ctx?: NuxtApp) => Promise<T>;

// Define the extended return type
export type DeferredAsyncData<T, F> = ReturnType<
  typeof useAsyncData<Maybe<T>, F>
> & {
  ensure: () => Promise<T>;
  data: ReturnType<typeof computed<T | undefined>>;
};

export function useDeferredAsyncData<T, F>(
  handler: Handler<T>,
  options?: Options<T>,
): DeferredAsyncData<T, F>;

export function useDeferredAsyncData<T, F>(
  key: string,
  handler: Handler<T>,
  options?: Options<T>,
): DeferredAsyncData<T, F>;

export function useDeferredAsyncData<T, F>(
  keyOrHandler: string | Handler<T>,
  handlerOrOptions: Handler<T> | Options<T> | undefined,
  options?: Options<T> | undefined,
) {
  const isKeyProvided = typeof keyOrHandler === "string";
  const key = isKeyProvided ? (keyOrHandler as string) : undefined;
  const handler = isKeyProvided
    ? (handlerOrOptions as Handler<T>)
    : (keyOrHandler as Handler<T>);
  const opts = isKeyProvided
    ? options
    : (handlerOrOptions as Options<T> | undefined);

  // Set the default options
  const defaultOptions = {
    lazy: true,
    immediate: false,
    server: false,
  };

  // Merge the passed options with the defaults
  const mergedOptions = { ...defaultOptions, ...options };

  // Call useAsyncData with the merged options
  const asyncData = isKeyProvided
    ? useAsyncData<Maybe<T>, F>(key as string, handler, mergedOptions)
    : useAsyncData<Maybe<T>, F>(handler, mergedOptions);

  const res = ref();
  const rej = ref();
  const generateNewPromise = () =>
    new Promise<T>((resolve, reject) => {
      res.value = resolve;
      rej.value = reject;
    });
  const p = ref(generateNewPromise());

  watch(asyncData.status, (status) => {
    switch (status) {
      case "success":
        res.value(asyncData.data.value as T);
        break;
      case "error":
        rej.value(asyncData.error.value);
        break;
      case "idle":
        p.value = generateNewPromise();
        break;
      case "pending":
        // ...
        break;
    }
  });

  const data = computed({
    get() {
      if (asyncData.status.value === "idle") {
        asyncData.refresh();
        return undefined;
      }

      return asyncData.data.value as T;
    },
    set(value) {
      asyncData.data.value = value;
    },
  });

  return {
    ...asyncData,
    ensure: () => {
      // eslint-disable-next-line no-unused-expressions
      data.value;

      // return promise
      return p.value;
    },
    data,
  };
}

import axios from "axios";

export const useApiStore = defineStore("api", () => {
  const auth = useAuthStore();
  const { public: $env } = useRuntimeConfig();

  const bearerToken = computed(() => auth.authResponseParams?.oauth_token);

  const api = axios.create({
    baseURL: $env.STREAMLABS_API_BASE_PATH,
    // withCredentials: true,
  });

  api.interceptors.request.use((config) => {
    if (!config?.headers?.Authorization && bearerToken.value) {
      config["headers"]["Authorization"] = `Bearer ${bearerToken.value}`;
    }

    return config;
  });

  const w = <T>(url: string) => {
    return useDeferredAsyncData(
      url,
      async () => {
        return await api.get<T>(url).then(({ data }) => data);
      },
      {
        // if bearer token changes, refetch all the routes
        watch: [bearerToken],
      },
    );
  };

  return {
    get: api.get,
    post: api.post,
    put: api.put,
    delete: api.delete,

    streamLabels: {
      appSettings: w<StreamLabelsAppSettingsResponse>(
        `/api/v5/slobs/stream-labels/app-settings`,
      ),
      files: w<StreamLabelsFilesResponse>(
        `/api/v5/obs-plugin/stream-labels/files`,
      ),
      settings: w<StreamLabelsSettingsResponse>(
        `/api/v5/slobs/stream-labels/settings`,
      ),
    },
    multistream: {
      ingest: w<RstIngestResponse>(`/api/v5/obs-plugin/rst/ingest`),
      user: {
        platforms: w<RstUserPlatformsResponse>(
          `/api/v5/obs-plugin/rst/user/platforms`,
        ),
        profile: w<RstUserProfileResponse>(
          `/api/v5/obs-plugin/rst/user/profile`,
        ),
        targets: w<RstUserTargetsResponse>(
          `/api/v5/obs-plugin/rst/user/targets`,
        ),
      },
    },
    golive: {
      youtube: {
        info: w<GoLiveYouTubeInfoResponse>(
          "/api/v5/obs-plugin/go-live/youtube/info",
        ),
      },
      tiktok: {
        info: w<GoLiveTikTokInfoResponse>(
          "/api/v5/obs-plugin/go-live/tiktok/info",
        ),
        apply: w<{ url: string }>(`/api/v5/obs-plugin/go-live/tiktok/apply`),
        links: w<{ monitor: string }>(
          `/api/v5/obs-plugin/go-live/tiktok/links`,
        ),
      },
    },
    platformInfo: w<UserPlatformInfoResponse>(
      `/api/v5/obs-plugin/user/platform-info`,
    ),
  };
});

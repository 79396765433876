import Bindings from "./bindings";
import { captureException } from "~/plugins/sentry";
import { generateRandomString } from "@/lib/util";

export enum DockArea {
  Top = 0x4,
  Right = 0x2,
  Bottom = 0x8,
  Left = 0x1,
}

export class PluginObsDock {
  floating: boolean;
  height: number;
  isSlabs: boolean;
  name: string;
  url: string;
  visible: boolean;
  width: number;
  x: number;
  y: number;
  title: string;

  constructor(p: PluginObsDock) {
    this.floating = p.floating;
    this.height = p.height;
    this.isSlabs = p.isSlabs;
    this.name = p.name;
    this.url = p.url;
    this.visible = p.visible;
    this.width = p.width;
    this.x = p.x;
    this.y = p.y;
    this.title = p.title;
  }

  async setTitle(title: string) {
    await Bindings.dock.setTitle(this.name, title);
    this.title = title;
    return this;
  }

  async setSize(width: number, height: number) {
    await Bindings.dock.resize(this.name, width, height);
    this.width = width;
    this.height = height;
    return this;
  }

  async setUrl(url: string) {
    await Bindings.dock.setURL(this.name, url);
    this.url = url;
    return this;
  }

  async show() {
    await Bindings.dock.toggleDockVisibility(this.name, true);
    this.visible = true;
    return this;
  }

  async hide() {
    await Bindings.dock.toggleDockVisibility(this.name, false);
    this.visible = false;
    return this;
  }

  async setArea(area: DockArea) {
    await Bindings.dock.setArea(this.name, area);
  }

  async executeJavascript(js: string) {
    await Bindings.dock.executeJavascript(this.name, js);
  }
}

export const getDocks = () =>
  Bindings.dock.queryAll().then((items) => {
    if (!items?.[Symbol.iterator]) {
      console.error(`items = ${JSON.stringify(items)}`);
      captureException(`!items?.[Symbol.iterator]`);
    }

    return items.map((item) => new PluginObsDock(item));
  });

export const getPluginDocks = async () =>
  await getDocks().then((docks) => docks.filter((dock) => dock.isSlabs));

export const createDock = async ({
  title,
  url,
  id,
}: {
  title: string;
  url: string;
  id: string;
}) => {
  await Bindings.dock.newBrowserDock(title, url, id);

  const docks = await getDocks();

  const dock = docks.find((dock) => dock.name === id);

  if (!dock) {
    throw new Error(`!dock`);
  }

  return dock;
};

const defaultDocks: {
  id: DockName;
  title: DockTitle;
  area: DockArea;
  url: string;
  width: number;
  height?: number;
}[] = [
  {
    id: "streamlabs-dock-recent-events",
    title: "Streamlabs: Recent Events",
    area: DockArea.Left,
    url: `${window.location.origin}/docks/recent-events`,
    width: 500,
    height: undefined,
  },
  {
    id: "streamlabs-dock-chat",
    title: "Streamlabs: Chat",
    area: DockArea.Right,
    url: `${window.location.origin}/docks/chat`,
    width: 500,
    height: undefined,
  },
];

export const reloadPluginDocks = async () => {
  const ourDocks = await getPluginDocks();

  for (const dock of ourDocks) {
    const defaulDock = defaultDocks.find(({ title }) => title === dock.title);
    if (defaulDock) {
      await dock.setUrl(defaulDock.url);
    }
  }
};

export const restoreDocks = async () => {
  const ourDocks = await getPluginDocks();

  for (const defaultDock of defaultDocks) {
    // repurpose or create a new dock
    const dock =
      ourDocks.shift() ??
      (await createDock({
        title: defaultDock.title,
        url: defaultDock.url,
        id: generateRandomString(),
      }));

    await dock.setTitle(defaultDock.title);
    await dock.setUrl(defaultDock.url);
    await dock.setArea(defaultDock.area);
    await dock.setSize(
      defaultDock.width ?? dock.width,
      defaultDock.height ?? dock.height,
    );

    await dock.show();
  }

  // hide any other docks that are ours (ideally delete them)
  for (const dock of ourDocks) {
    await dock.hide();
  }
};

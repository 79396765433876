import Bindings from "~/lib/bindings";

const useStore = defineStore(
  "app",
  () => {
    console.log("app.store");
    const dialogs = useDialogsStore();
    const { public: $env } = useRuntimeConfig();
    const { t: $t } = useI18n();

    const name = computed(
      () => $env.APP_NAME || $t("Streamlabs Plugin for OBS"),
    );

    const defaultDimentions = computed(() => {
      return {
        width: $env.APP_DEFAULT_WIDTH * window.devicePixelRatio,
        height: $env.APP_DEFAULT_HEIGHT * window.devicePixelRatio,
      };
    });

    const dynamicZoomEnabled = ref(false);
    const wantsBetaFeaturesEnabled = ref(false);

    const resetDimentions = () => {
      // call old version
      Bindings.browser.resizeBrowser(
        defaultDimentions.value.width,
        defaultDimentions.value.height,
      );
    };

    const onboardingComplete = ref(false);
    const startStreamingFlowEnabled = ref(true);
    const isAlphaMode = ref(false);
    const leftNavPinned = ref(false);

    const flagDefaults = {
      hasSeenGoLiveFlow: false,
    };

    const flags = reactive({ ...flagDefaults });

    type Flags = typeof flags;
    type FlagName = keyof Flags;

    function setFlag<T extends FlagName>(flag: T, value: Flags[T]): void {
      flags[flag] = value;
    }

    function resetAllFlags() {
      Object.keys(flagDefaults).forEach((flag) => {
        const key = flag as FlagName;
        console.log(key, flags[key], flagDefaults[key]);
        flags[key] = flagDefaults[key];
      });
    }

    onBeforeMount(() => {
      isAlphaMode.value =
        window.location.host.startsWith("localhost") ||
        window.location.host.startsWith("obs-plugin.streamlabs.dev") ||
        window.location.host.startsWith("obs-plugin.streamlabs.site");

      // dialogs.showDialog("ONBOARDING");
    });

    const bringToFront = () => {
      Bindings.browser.bringToFront();
      Bindings.browser.setHiddenState(false);
    };

    const hide = () => {
      Bindings.browser.setHiddenState(true);
    };

    const versionInfo = ref<VersionInfo>();
    const metaPublish = ref<VersionManifest | null>(null);
    const requiresUpdate = ref(false);

    const checkForUpdates = async () => {
      metaPublish.value = await useFetch<VersionManifest>(
        $env.PLUGIN_VERSION_MANIFEST_URL,
      ).then((res) => res.data.value);

      versionInfo.value = await Bindings.sl.getVersionInfo();

      if (+versionInfo.value.rev < $env.PLUGIN_VERSION_MIN) {
        dialogs.showDialog("PLUGIN_OUTDATED", {
          // if we already needed an update, it's likely that restart didn't auto update
          mode: requiresUpdate.value ? "download_updater" : "restart_obs",
        });

        requiresUpdate.value = true;
      } else {
        requiresUpdate.value = false;
      }
    };

    return {
      name,
      resetDimentions,
      bringToFront,
      hide,
      onboardingComplete,
      startStreamingFlowEnabled,
      dynamicZoomEnabled,
      wantsBetaFeaturesEnabled,

      isAlphaMode,
      leftNavPinned,

      versionInfo,
      metaPublish,
      checkForUpdates,
      requiresUpdate,

      // flags: readonly(flags),
      flags,

      setFlag,
      resetAllFlags,

      dispose() {
        console.log("dispose");
        // ...
      },
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);

export const useAppStore = useStore;

if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    useStore().dispose();
  });

  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
}

import { sha256 } from "js-sha256";
import { base64UrlEncode } from "./util";

export const generatePKCE = () => {
  const randomBytes = new Uint8Array(128);
  crypto.getRandomValues(randomBytes);

  const verifier = base64UrlEncode(randomBytes);

  // const hash = await crypto.subtle.digest(
  //   "SHA-256",
  //   new TextEncoder().encode(verifier),
  // );

  const hash = sha256.arrayBuffer(verifier);

  const challenge = base64UrlEncode(new Uint8Array(hash));

  return {
    verifier,
    challenge,
  };
};

export const decrypt = async (encryptedBase64Str: string, key: string) => {
  const { iv, value, mac }: { iv: string; value: string; mac: string } =
    JSON.parse(atob(encryptedBase64Str));

  // Convert the IV and encrypted data into Uint8Array
  const ivArray = Uint8Array.from(atob(iv), (c) => c.charCodeAt(0));
  const encryptedDataArray = Uint8Array.from(atob(value), (c) =>
    c.charCodeAt(0),
  );

  // Convert the key string into a CryptoKey
  const rawKey = Uint8Array.from(key, (c) => c.charCodeAt(0));
  const cryptoKey = await window.crypto.subtle.importKey(
    "raw",
    rawKey,
    { name: "AES-CBC", length: 256 },
    false,
    ["decrypt"],
  );

  // Decrypt the data
  const decryptedData = await window.crypto.subtle.decrypt(
    { name: "AES-CBC", iv: ivArray },
    cryptoKey,
    encryptedDataArray,
  );

  // Convert the decrypted data into a string
  return new TextDecoder().decode(decryptedData);
};

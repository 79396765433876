// Source: https://www.geeksforgeeks.org/how-to-create-a-guid-uuid-in-javascript/
export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function simpleHash(inputString: string): Uint8Array {
  const hash = new Uint8Array(20);
  for (let i = 0; i < inputString.length; i++) {
    hash[i % 20] = (hash[i % 20] + inputString.charCodeAt(i)) % 256;
  }
  return hash;
}

export function deterministicUUIDv4(inputString: string): string {
  // Hash the input string using the simple hash function
  const hash = simpleHash(inputString);

  // Get the first 16 bytes of the hash
  const bytes = Array.from(hash.slice(0, 16));

  // Set the version to 4 (UUIDv4)
  bytes[6] = (bytes[6] & 0x0f) | 0x40;
  // Set the variant to DCE 1.1
  bytes[8] = (bytes[8] & 0x3f) | 0x80;

  // Convert bytes to a UUID string
  const hex = bytes.map((b) => b.toString(16).padStart(2, "0")).join("");
  const uuid = [
    hex.slice(0, 8),
    hex.slice(8, 12),
    hex.slice(12, 16),
    hex.slice(16, 20),
    hex.slice(20, 32),
  ].join("-");

  return uuid;
}

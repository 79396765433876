import mitt from "mitt";
import Bindings from "~/lib/bindings";
import { setOnObsStreamButtonClicked } from "~/lib/core/obs-stream-button";
import { PDock } from "~/lib/obs/docks";
import { PScene } from "~/lib/obs/scenes";
import { PSource } from "~/lib/obs/sources";
import { equalityRef, setAsyncInterval } from "~/lib/util";

export const STREAMLABELS_SETTING_KEY = "_slLabel";

const useStore = defineStore("obs", () => {
  console.log("obs.store");
  const app = useAppStore();
  const dialogs = useDialogsStore();
  const { track: $track } = useTrackingStore();

  const isStreaming = equalityRef(false);

  type PluginDataSources = Awaited<
    ReturnType<typeof Bindings.obs.query_all_sources>
  >;

  const pluginData = {
    sources: equalityRef<
      (PluginDataSources[number] & {
        // we preload settings for text sources
        settings?: Record<string, any>;
      })[]
    >(),
    scenes: equalityRef<Awaited<ReturnType<typeof Bindings.obs.enum_scenes>>>(),
    docks: equalityRef<Awaited<ReturnType<typeof Bindings.dock.queryAll>>>(),
  };

  const sources = computed(() =>
    !pluginData.sources.value
      ? undefined
      : pluginData.sources.value.map((payload) => new PSource(payload)),
  );

  const scenes = computed(() =>
    !pluginData.scenes.value
      ? undefined
      : pluginData.scenes.value.map((payload) => new PScene(payload)),
  );

  const docks = computed(() =>
    !pluginData.docks.value
      ? undefined
      : pluginData.docks.value.map((payload) => new PDock(payload)),
  );

  const streamLabelSources = computed(() => {
    if (!sources.value) {
      return undefined;
    }

    return sources.value.filter((source) => {
      if (!source.settings) {
        return false;
      }

      return (
        source.settings[STREAMLABELS_SETTING_KEY] &&
        !source.settings.read_from_file
      );
    });
  });

  const bringToFront = () => Bindings.obs.bring_front();

  const getDockByTitle = (title: DockTitle) => {
    return docks.value?.find((dock) => dock.title === title);
  };

  // keep things synced with OBS
  const dispose = setAsyncInterval(async () => {
    const t = Date.now();
    [
      pluginData.sources.value,
      pluginData.scenes.value,
      pluginData.docks.value,
      isStreaming.value,
    ] = await Promise.all([
      Bindings.obs.query_all_sources().then((sources) => {
        return Promise.all(
          sources.map((source) => {
            if (source.id.startsWith("text_")) {
              return Bindings.obs
                .source_get_settings_json(source.name)
                .then((settings) => {
                  return {
                    ...source,
                    settings,
                  };
                });
            }

            return source;
          }),
        );
      }),
      Bindings.obs.enum_scenes(),
      Bindings.dock.queryAll(),
      Bindings.obs
        .frontend_streaming_active()
        .then(({ value: isStreaming }) => isStreaming),
    ]);

    const took = Date.now() - t;

    if (took > 50) {
      console.warn(`updated: ${took}ms`);
    }
  }, 1000);

  setOnObsStreamButtonClicked(
    useDebounce(
      () => {
        if (!app.startStreamingFlowEnabled) {
          $track("going_live_via_obs_started");
          Bindings.qt.click_stream_button();
          return;
        }

        if (!isStreaming.value) {
          dialogs.showDialog("GO_LIVE");
          app.bringToFront();
          $track("going_live_via_plugin_opened");
        } else {
          Bindings.qt.click_stream_button();
        }
      },
      500,
      { leading: true, trailing: false },
    ),
  );

  watch(
    isStreaming,
    (is, was) => {
      if (isStreaming.value) {
        $track("go_live");
      } else if (!is && was) {
        $track("stopped_streaming");
      }
    },
    { immediate: true },
  );

  const emitter = mitt<{
    sourceAdded: PSource;
    sourceRemoved: string;
  }>();

  watch(sources, (is, was) => {
    if (!is || !was) {
      return;
    }

    const newSources = is.filter((source) => !was.includes(source));
    const removedSources = was.filter((source) => !is.includes(source));

    for (const pSource of newSources) {
      emitter.emit("sourceAdded", pSource);
    }

    for (const { name } of removedSources) {
      emitter.emit("sourceRemoved", name);
    }
  });

  return {
    bringToFront,
    getDockByTitle,

    docks,
    scenes,
    sources,

    streamLabelSources,
    isStreaming,

    dispose,

    emitter,
  };
});

export const useObsStore = useStore;

if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    useStore().dispose();
  });

  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
}

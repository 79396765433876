import type { WritableComputedRef, UnwrapRef } from "vue";

// from core https://github.com/twitchalerts/twitchalerts/blob/84beecc4cf6b0715845cec9a92296c8b622f4a83/laravel/resources/util/utility.js#L241C1-L255C2
export function getPreviewUrl(url?: any) {
  return url && typeof url === "string"
    ? url.replace(/\.(mp4|webm|jpe?g|png)$/, "-preview.jpeg")
    : null;
}

export function getOverlayPreviewImage(
  themeObj: Overlay,
  customImageKey: CustomImageType | null = null,
) {
  return (
    getPreviewUrl(
      (customImageKey && themeObj?.custom_images?.[customImageKey]) ||
        themeObj?.custom_images?.live ||
        themeObj?.custom_images?.start ||
        themeObj?.custom_images?.brb ||
        themeObj?.custom_images?.offline ||
        themeObj?.preview_images?.[0],
    ) || undefined
  );
}

export function previewVideo(overlay: Overlay) {
  if (!overlay) return undefined;

  const videoSrc = overlay.custom_images.start || overlay.custom_images.brb;

  if (videoSrc) {
    if (videoSrc.includes(".mp4") || videoSrc.includes(".webm")) {
      return videoSrc;
    }
  }

  return undefined;
}

export const generateRandomString = () => Math.random().toString(20).slice(2);
export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function equalityRef<T>(initial: T): WritableComputedRef<T>;
export function equalityRef<T>(): WritableComputedRef<T | undefined>;
export function equalityRef<T>(
  initial?: T,
): WritableComputedRef<T | undefined> {
  const v = ref<T | undefined>(initial);

  return computed({
    get(): T | undefined {
      return v.value as T | undefined;
    },
    set(newValue: T | undefined) {
      if (!isEqual(v.value, newValue)) {
        v.value = newValue as UnwrapRef<T> | undefined;
      }
    },
  });
}

export const setAsyncInterval = (
  asyncFn: () => any | Promise<any>,
  interval: number,
  // { immediate = true }: { immediate?: boolean } = {},
) => {
  let shouldContinue = true;

  async function loop() {
    const startTime = Date.now();

    await asyncFn(); // Execute the async function

    const endTime = Date.now();
    const runTime = endTime - startTime;

    if (shouldContinue) {
      setTimeout(loop, Math.max(interval - runTime, 0)); // Wait for the remainder of the interval
    }
  }

  loop();

  return () => {
    shouldContinue = false;
  };
};

export const base64UrlEncode = (bytes: Uint8Array) => {
  const str = String.fromCharCode(...Array.from(bytes));
  return btoa(str).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
};

// export const updateRefIfDifferent = <T>(ref: Ref<T>, v: T) => {
//   if (!isEqual(toRaw(ref.value), v)) {
//     ref.value = v;
//     return true;
//   }
// };

import type { UltraCheckoutParams } from "~/lib/ultra";
import { generateRandomString, sleep } from "~/lib/util";

type DialogName =
  | "RESTART_OBS"
  | "ULTRA_REQUIRED"
  | "ONBOARDING"
  | "BETA_RESTRICTED"
  | "PLUGIN_OUTDATED"
  | "GO_LIVE"
  | "FEEDBACK"
  | "TIKTOK_APPLY";

export type DialogProps = {
  ULTRA_REQUIRED: {
    reason: UltraUpsellShownReason;
    params: UltraCheckoutParams;
  };
  PLUGIN_OUTDATED: {
    mode: "download_updater" | "restart_obs";
  };
};

type Dialogs = {
  RESTART_OBS: boolean;
  ULTRA_REQUIRED: false | DialogProps["ULTRA_REQUIRED"];
  ONBOARDING: boolean;
  BETA_RESTRICTED: boolean;
  PLUGIN_OUTDATED: false | DialogProps["PLUGIN_OUTDATED"];
  GO_LIVE: boolean;
  FEEDBACK: boolean;
  TIKTOK_APPLY: boolean;
};

type NonBooleanDialogKeys = {
  [K in keyof Dialogs]: Dialogs[K] extends boolean ? never : K;
}[keyof Dialogs];

type BooleanDialogKeys = {
  [K in keyof Dialogs]: Dialogs[K] extends boolean ? K : never;
}[keyof Dialogs];

type DialogConfirm = {
  type: "confirm";
  id: string;
  title: string;
  description: string;
  busy: boolean;

  cancelBtnFn: Function;
  cancelBtnText: string;

  confirmBtnFn: Function;
  confirmBtnText: string;
};

type DialogGeneric = {
  id: string;
  isBusy?: boolean;
  title?: string;
  description?: string;
  html?: string;
  buttons?: {
    text: string;
    type?: "cancel" | "action";
    onClick: ({
      closeDialog,
      setDialogBusy,
    }: {
      closeDialog: () => void;
      setDialogBusy: (busy: boolean) => void;
    }) => void;

    variant?: ButtonProps["variant"];
    classes?: string | string[];
  }[];

  close: () => void;
  setBusy: (busy: boolean) => void;
};

type DialogCommon = DialogConfirm;

import { type Props as ButtonProps } from "~/components/common/Button/config";

export const useDialogsStore = defineStore("dialogs", () => {
  const dialogs = reactive<Dialogs>({
    RESTART_OBS: false,
    ULTRA_REQUIRED: false,
    ONBOARDING: false,
    BETA_RESTRICTED: false,
    PLUGIN_OUTDATED: false,
    GO_LIVE: false,
    FEEDBACK: false,
    TIKTOK_APPLY: false,
  });

  const commonDialogs = reactive<DialogCommon[]>([]);
  const genericDialogs = reactive<DialogGeneric[]>([]);

  const app = useAppStore();

  function showDialog<T extends BooleanDialogKeys>(dialog: T): void;
  function showDialog<T extends NonBooleanDialogKeys>(
    dialog: T,
    value: Dialogs[T],
  ): void;

  function showDialog<T extends keyof Dialogs>(
    dialog: T,
    value: Dialogs[T] = true as Dialogs[T],
  ): void {
    dialogs[dialog] = value;

    if (value) {
      app.bringToFront();
    }
  }

  const hideDialog = (dialog: DialogName) => {
    dialogs[dialog] = false;
  };

  type ConfirmContext = (context: {
    close: () => void;
    setBusy: (busy: boolean) => void;
  }) => void;

  const openDialog = (
    dialog: Omit<DialogGeneric, "id" | "close" | "setBusy">,
  ) => {
    const id = generateRandomString();
    const close = () => {
      const idx = genericDialogs.findIndex((item) => item.id === id);

      if (idx >= 0) {
        genericDialogs.splice(idx, 1);
      }
    };

    const setBusy = (isBusy: boolean) => {
      const idx = genericDialogs.findIndex((item) => item.id === id);
      if (idx >= 0) {
        genericDialogs[idx].isBusy = isBusy;
      }
    };

    genericDialogs.push({
      ...dialog,
      id,
      isBusy: false,

      close,
      setBusy,
    });
  };

  const showConfirmDialog = ({
    title,
    description,
    confirmBtnText = "Confirm",
    cancelBtnText = "Cancel",
    onConfirm,
    onCancel,
  }: {
    title: string;
    description: string;
    onConfirm?: ConfirmContext;
    onCancel?: ConfirmContext;
    confirmBtnText?: string;
    cancelBtnText?: string;
  }) => {
    const id = generateRandomString();
    const close = () => {
      const idx = commonDialogs.findIndex((item) => item.id === id);

      if (idx >= 0) {
        commonDialogs.splice(idx, 1);
      }
    };

    const setBusy = (busy: boolean) => {
      const idx = commonDialogs.findIndex((item) => item.id === id);
      if (idx >= 0) {
        commonDialogs[idx].busy = busy;
      }
      // ...
    };

    commonDialogs.push({
      type: "confirm",
      id,
      title,
      description,
      busy: false,

      cancelBtnText,
      cancelBtnFn: onCancel ? () => onCancel({ close, setBusy }) : close,

      confirmBtnText,
      confirmBtnFn: onConfirm ? () => onConfirm({ close, setBusy }) : close,
    });
  };

  /*
  onBeforeMount(() => {
    dialogs.GO_LIVE = false;
    dialogs.RESTART_OBS = false;
    dialogs.ULTRA_REQUIRED = false;
    dialogs.PLUGIN_OUTDATED = false;
    dialogs.FEEDBACK = false;
    dialogs.ONBOARDING = false;
  });
  */

  return {
    dialogs,
    openDialog,
    showDialog,
    hideDialog,

    commonDialogs,
    genericDialogs,
    showConfirmDialog,
  };
});

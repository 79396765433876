import Bindings from "../bindings";
import type { PluginObsDock } from "../docks";

type Payload = PluginObsDock;

export enum DockArea {
  Top = 0x4,
  Right = 0x2,
  Bottom = 0x8,
  Left = 0x1,
}

export class PDock {
  name!: string;
  url!: string | undefined;
  title!: string;
  x!: number;
  y!: number;
  width!: number;
  height!: number;
  visible!: boolean;
  isSlabs!: boolean;

  constructor(payload: Payload) {
    this.patch(payload);
  }

  patch({ name, url, title, x, y, width, height, visible, isSlabs }: Payload) {
    this.name = name;
    this.url = url;
    this.title = title;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.visible = visible;
    this.isSlabs = isSlabs;
  }

  async setTitle(title: string) {
    await Bindings.dock.setTitle(this.name, title);
    this.title = title;
    return this;
  }

  async setSize(width: number, height: number) {
    await Bindings.dock.resize(this.name, width, height);
    this.width = width;
    this.height = height;
    return this;
  }

  async setUrl(url: string) {
    await Bindings.dock.setURL(this.name, url);
    this.url = url;
    return this;
  }

  async show() {
    await Bindings.dock.toggleDockVisibility(this.name, true);
    this.visible = true;
    return this;
  }

  async hide() {
    await Bindings.dock.toggleDockVisibility(this.name, false);
    this.visible = false;
    return this;
  }

  async setArea(area: DockArea) {
    await Bindings.dock.setArea(this.name, area);
  }

  async executeJavascript(js: string) {
    await Bindings.dock.executeJavascript(this.name, js);
  }
}

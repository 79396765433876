import Bindings from "../bindings";

declare global {
  interface Window {
    obsStreamButtonClicked?: Function;
  }
}

export const setOnObsStreamButtonClicked = (cb: Function) => {
  window.obsStreamButtonClicked = cb;
  Bindings.qt.set_js_on_click_stream("window.obsStreamButtonClicked();");
};
